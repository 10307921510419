import React from 'react';
import styled from 'styled-components';
import { Button } from '@womcorp/wom-ui-kit';
import SEO from '../components/seo';
import { LoginLayout } from '../components/Layouts';
import { AuthService } from '../services';
import { InOutCardResponsive } from '../components';
import { formatRut } from '../utils/formatters';
import { ReactComponent as Error404Icon } from '../assets/images/error404-icon.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/images/whatsapp-icon.svg';

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 545px;
  color: #381451;
`;

const CardIconWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const CardTextWrapper = styled.div`
  text-align: center;
  margin-top: 30px;

  p {
    margin-top: 25px;
  }
`;

const RutWrapper = styled.span`
  font-weight: bold;
`;

const CardButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

const WhatsAppWrapper = styled.div`
  @media (max-width: 768px) {
    align-self: center;
    margin-top: 10px;
  }
`;

const WhatsAppButton = styled(Button)`
  display: flex;
  border-radius: 30px;
  border: 2px solid #381451;
  padding: 8px 15px;

  span {
    align-self: center;
    margin-left: 5px;
  }
`;

const WhatsAppLink = styled.a``;

const CallNumberWrapper = styled.div`
  @media (max-width: 768px) {
    margin-top: 10px;
    align-self: center;
  }
`;

const CallNumberButton = styled(Button)`
  display: flex;
  border-radius: 30px;
  border: 2px solid #381451;
  padding: 15px 20px;
  cursor: default;
  text-transform: none;
  font-weight: 500;

  span {
    align-self: center;
  }
`;

const MailError = () => {
  const enteredRutNumber = AuthService.getRutNumber();
  const formattedRutNumber = formatRut(enteredRutNumber);
  const API_BASE_WHATSAPP = 'https://api.whatsapp.com/send?phone=56937400691';

  return (
    <>
      <SEO title="Login" />
      <LoginLayout>
        <CustomCardWrapper>
          <CardIconWrapper>
            <Error404Icon />
          </CardIconWrapper>
          <CardTextWrapper>
            El RUT <RutWrapper>{formattedRutNumber}</RutWrapper> no tiene un correo
            correo asociado para que te puedas registrar en MiWOM Fibra, lo
            lamentamos.
            <p>Para regularizar esta situación</p>
          </CardTextWrapper>
          <CardButtonWrapper>
            <CallNumberWrapper>
              <CallNumberButton variant="secondary" size="l">
                <span>Llámanos al 600 600 1106</span>
              </CallNumberButton>
            </CallNumberWrapper>
            <WhatsAppWrapper>
              <WhatsAppLink href={API_BASE_WHATSAPP} target="_blank">
                <WhatsAppButton variant="secondary" size="l">
                  <WhatsAppIcon />
                  <span>WHATSAPP EJECUTIVO</span>
                </WhatsAppButton>
              </WhatsAppLink>
            </WhatsAppWrapper>
          </CardButtonWrapper>
        </CustomCardWrapper>
      </LoginLayout>
    </>
  );
};

export default MailError;
